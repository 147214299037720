footer {
    background-color: var(--theme-light-white);
    display: flex;
    flex-flow: column wrap;
}

.footer.dark-mode {
    background-color: var(--theme-secondary-black);
}

footer .contact__me .contact .contact__info {
    margin-top: 5rem;
    display: flex;
}

footer .contact__me .contact .show-email {
    margin-top: 0;
    margin-left: -6rem;
}

.contact__me {
    width: 100%;
    padding: 2rem 3rem;
}


.contact__me-title {
    font-size: 3rem;
    color: var(--theme-black);
}

.footer.dark-mode .contact__me .contact__me-title {
    color: var(--theme-secondary-light-white);
}

.contact__me-subtitle {
    margin-top: 1rem;
    margin-bottom: -2rem;
    color: var(--theme-black);
    font-size: 1.3rem;
    font-weight: 500;
}

.footer.dark-mode .contact__me .contact__me-subtitle {
    color: var(--theme-secondary-light-white);
}

.testimonials {
    width: 100%;
    background-color: var(--theme-secondary-dark-blue);
    padding: 2rem 3rem;
}

.testimonial__image {
    display: flex;
    justify-content: space-between;
}
.testimonial__image img:first-child {
    margin-top: -2rem;
}

.testimonial__image img:last-child {
    width: 5rem;
    height: 5rem;
    border: 2px solid var(--theme-secondary-dark-white);
    border-radius: 16rem;
}

.testimonials p {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {
    
}


/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
    #contact {
        flex-flow: row;
    }
    .contact__me {
        width: 50%;
    }
    .testimonials {
        width: 50%;
    }
}


/* Desktop */
@media only screen and (min-width: 1440px) {
    .contact__me-title {
        font-size: 4rem;
    }

    .contact__me-subtitle {
        font-size: 1.6rem;
    }

    .testimonials p {
        font-size: 1.6rem;
    }

    footer .contact__me .contact .show-email {
        margin-left: -9rem;
    }
}

/* Max Desktop */
@media only screen and (min-width: 1920px) {
    .contact__me-title {
        font-size: 80px;
    }

    .contact__me-subtitle {
        font-size: 32px;
    }

    .testimonials p {
        font-size: 32px;
    }

    footer .contact__me .contact .show-email {
        margin-left: -192px;
    }
    
}