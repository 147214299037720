header {
    width: 100vw;
    height: 7em;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__container {
    width: 80%;
    height: 4rem;
    background-color: var(--theme-secondary-black-translucent);
    margin-top: 3rem;
    border: 0.5px solid var(--theme-dark-white-translucent);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(1rem);
    z-index: 2147483638;
    transition-timing-function: ease-in;
    transition: 0.5s;
}

.hide-nav {
    transform: translateY(-400px);
}

.header__left img {
    width: 10rem;
}

.header__right {
    display: none;
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {
    
}


/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
    .header__right {
        margin-right: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .header__right a {
        margin-right: 1.2rem;
        color: var(--theme-secondary-light-white);
        font-family: 'Mark Pro Bold', sans-serif;
        font-size: 1.2rem;
        text-decoration: none;
    }
    
    .header__right a:hover {
        color: var(--theme-secondary-dark-white);
    }
}


/* Desktop */
@media only screen and (min-width: 1440px) {
    .header__right a {
        font-size: 1.5rem;
    }
}

/* Max Desktop */
@media only screen and (min-width: 1920px) {
    .header__right a {
        font-size: 24px;
    }
}