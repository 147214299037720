.contact {
    display: flex;
    align-items: center;
    flex-flow: column;
}

.contact__info {
    margin-top: 5rem;
    display: flex;
    justify-content: flex-end;
}

.contact__points {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.moved {
    animation: 1s slide-left forwards;
}

.email {
    margin-left: 0.3rem;
    cursor: pointer;
}

.show-email {
    padding: 0.2rem;
    display: block;
    cursor: text;
    position: absolute;
    animation: 1s appear forwards;
    border: 1px solid var(--theme-dark-white);
    border-radius: 4px;
    color: var(--theme-black);
}

.contact.dark-mode .contact__info .show-email {
    border: 1px solid var(--theme-secondary-black);
    color: var(--theme-secondary-light-white);
}

.show-email:hover {
    color: var(--theme-secondary-dark-white);
}

.hide-email {
    animation: 1s slide-right forwards;
    display: none;
}

.show-hint {
    color: var(--theme-dark-white);
    display: flex;
    align-self: center;
}

.contact.dark-mode .show-hint {
    color: var(--theme-hint-black);
}

.hide-hint {
    color: transparent;
}

.contact-landing {
    display: none;
}

.contact__points {
    margin-top: 1rem;
}

@keyframes slide-left {
    to {
        transform: translateX(-600%);
    }
}

@keyframes slide-right {
    to {
        transform: translateX(0%);
    }
}

@keyframes appear {
    0% {
        display: none;
        transform: translateX(100%);
    }

    100% {
        display: block;
        transform: translateX(-50%);
    }
}

@keyframes slide-left {
    to {
        transform: translateX(-300%);
    }
}

@keyframes appear {
    0% {
        display: none;
        transform: translateX(100%);
    }

    100% {
        display: block;
        transform: translateX(5%);
    }
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {
    
}


/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
    .contact__info {
        margin-top: 0;
    }
    .contact__points {
        margin-top: 0;
    }
}


/* Desktop */
@media only screen and (min-width: 1440px) {
    .contact__points img {
        width: 3rem;
    }
    .contact__info p {
        font-size: 1.8rem;
    }
    .show-hint {
        font-size: 1.2rem;
    }
}

/* Max Desktop */
@media only screen and (min-width: 1920px) {
    .contact__points img {
        width: 64px;
    }
    .contact__info p {
        font-size: 32px;
    }
    .show-hint {
        font-size: 24px;
    }
}