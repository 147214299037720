/* CSS Reset */
@font-face {
  font-family: 'Mark Pro Bold';
  src: url(../public/fonts/Mark-Pro-Bold/Mark-Pro-Bold.ttf);
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
textarea,
select {
  font: inherit;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
/* @media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
} */

:root {
  --theme-black: #0b090a;
  --theme-secondary-black: #161a1d;
  --theme-hint-black: #20272d;
  --theme-secondary-black-translucent: rgba(22, 26, 29, 25%);
  --theme-dark-red: #660708;
  --theme-secondary-dark-red: #a4161a;
  --theme-light-red: #ba181b;
  --theme-secondary-light-red: #e5383b;
  --theme-dark-white: #b1a7a6;
  --theme-dark-white-translucent: rgba(72, 67, 66, 25%);
  --theme-secondary-dark-white: #d3d3d3;
  --theme-light-white: #f5f3f4;
  --theme-secondary-light-white: #ffffff;
  --theme-light-pink: #f72585;
  --theme-dark-pink: #b5179e;
  --theme-light-purple: #7209b7;
  --theme-secondary-light-purple: #560bad;
  --theme-dark-purple: #480ca8;
  --theme-secondary-dark-purple: #3a0ca3;
  --theme-dark-blue: #3f37c9;
  --theme-secondary-dark-blue: #4361ee; 
  --theme-light-blue: #4895ef;
  --theme-secondary-light-blue: #4cc9f0;
  
}

/* CSS Reset End */

html {
  background-color: var(--theme-secondary-white);
}

.App.dark-mode {
  background-color: var(--theme-black);
}

h1, h2, h3, h4, h5, h6, p {
  color: var(--theme-secondary-light-white);
}

.hidden {
  opacity: 0 !important;
  transform: translateY(0) !important;
}


@keyframes slide {
  0% {
      margin-top: -37.5rem;
  }

  5% {
      margin-top: -22.75rem;
  }

  33% {
      margin-top: -22.75rem;
  }

  38% {
      margin-top: -11.25rem;
  }

  66% {
      margin-top: -11.25rem;
  }

  71% {
      margin-top: 0.25rem;
  }

  100% {
      margin-top: 0.25rem;
  }
}

@keyframes animate {
  0% {
      background-position: 0%;
  }

  100% {
      background-position: 100%;
  }
}

@media (prefers-color-scheme: dark) {

}

@media (prefers-color-scheme: light) {

}