#hero {
    width: 98vw;
    height: 100vh;
    font-family: "Mark Pro Bold", sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 2rem;
}

.hero__title {
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: 0rem;
}

.hero__greeting {
    font-size: 2em;
}

.hero .hero__title .hero__greeting {
    color: var(--theme-black);
}

.hero.dark-mode .hero__title .hero__greeting {
    color: var(--theme-secondary-light-white);
}

.hero__name {
    font-size: 2em;
    background:
        linear-gradient(-45deg,
            var(--theme-light-pink),
            var(--theme-dark-pink),
            var(--theme-light-purple),
            var(--theme-secondary-light-purple),
            var(--theme-dark-purple),
            var(--theme-secondary-dark-purple),
            var(--theme-dark-blue),
            var(--theme-secondary-dark-blue),
            var(--theme-light-blue),
            var(--theme-secondary-light-blue),
            var(--theme-light-blue),
            var(--theme-secondary-dark-blue),
            var(--theme-dark-blue),
            var(--theme-secondary-dark-purple),
            var(--theme-dark-purple),
            var(--theme-secondary-light-purple),
            var(--theme-light-purple),
            var(--theme-dark-pink),
            var(--theme-light-pink));
    background-size: 2000%;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: animate 7s linear infinite;
}


.hero__slider {
    width: 100%;
    height: 6rem;
    position: relative;
    font-size: 1em;
    overflow: hidden;
}

.hero__slider div {
    height: 5.5rem;
    margin-bottom: 6rem;
    font-size: 1.5rem;
    text-align: center;
}

.hero .hero__title .hero__slider {
    color: var(--theme-black);
}

.hero.dark-mode .hero__title .hero__slider {
    color: var(--theme-secondary-light-white);
}


.hero__slider-text1 {
    animation: slide 5s linear infinite;
}

.hero button {
    
    color: var(--theme-black);
}

.hero.dark-mode button {
    color: var(--theme-secondary-light-white);
}

.hero__banner {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.hero__cta {
    color: var(--theme-dark-white);
    letter-spacing: 0.3rem;
    text-decoration: none;
}

.hero__banner .contact .show-email {
    margin-top: 0.85rem;
    margin-left: -6rem;
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {
    .hero__title {
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: 0rem;
    }

    .hero__slider div {
        font-size: 2rem;
    }
}


/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
    .hero__title {
        width: 38rem;
    }

    .hero__slider div {
        font-size: 3rem;
    }

    .hero__banner {
        bottom: 2rem;
        display: flex;
        justify-content: space-around;
    }

    .hero__cta {
        text-wrap: nowrap;
    }

    .hero__banner .contact .show-email {
        margin-top: 0;
    }
}


/* Desktop */
@media only screen and (min-width: 1440px) {
    .hero__title {
        width: 50rem;
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0;
    }

    .hero__slider {
        width: 51rem;
    }

    .hero__slider div {
        font-size: 4rem;
    }

    .hero__cta {
        font-size: 1.2rem;
    }

    .hero__banner .contact .show-email {
        margin-left: -9rem;
    }
}

/* Max Desktop */
@media only screen and (min-width: 1920px) {
    .hero__title {
        width: 800px;
        font-size: 64px;
        line-height: 80px;
    }

    .hero__slider {
        width: 816px;
    }

    .hero__slider div {
        font-size: 64px;
    }

    .hero__cta {
        font-size: 19.2px;
    }

    .hero__banner .contact .show-email {
        margin-left: -192px;
    }
}