#projects {
  padding: 4rem 0;
  opacity: 1;
  transform: translateY(-2rem);
  transition: all 1s ease-in 0.5s;
}

.project__header {
  margin-bottom: 4rem;
}

.project__header h2 {
  color: var(--theme-secondary-black);
  font-size: 4rem;
  line-height: 4rem;
  text-align: center;
}

.project.dark-mode .project__header h2 {
  color: var(--theme-secondary-light-white);
}

.project__portfolio {
  display: flex;
  flex-flow: column;
}

.project__card-container {
  padding: 2rem;
  background-color: var(--theme-secondary-black);
}

.project__card-content {
  margin-bottom: 1rem;
}

.project__card-content h5 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
}

.project__card-content p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--theme-secondary-dark-white);
}

.project__card-content a {
  color: var(--theme-dark-white);
  text-decoration: underline;
  text-decoration-color: var(--theme-secondary-dark-white);
}

.project__card-tech {
  display: flex;
  flex-wrap: wrap;
}

.project__card-img:focus-visible img{
  box-sizing: border-box;
  border: 4px solid var(--theme-secondary-dark-blue);
  border-radius: 0;
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {}


/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
  .project__portfolio {
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 4rem;
  }

  .project__card.featured {
    width: 60rem;
  }

  .project__card {
    width: 28rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  .project__card.featured>a>img {
    height: auto;
    object-fit: cover;
  }

  .project__card>a>img {
    height: 20rem;
    object-fit: cover;
  }

  .project__card-container {
    height: 20rem;
  }
}


/* Desktop */
@media only screen and (min-width: 1440px) {
  .project__portfolio {
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 4rem;
  }

  .project__card.featured {
    width: 90rem;
  }

  .project__card {
    width: 43rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  .project__card.featured>a>img,
  .project__card>a>img {
    width: 100%;
    object-fit: cover;
  }

  .project__card-container {
    height: 23rem;
  }

  .project__card-content h5 {
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 600;
  }
  
  .project__card-content p,
  .project__card-content a {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

/* Max Desktop */
@media only screen and (min-width: 1920px) {
  
  
  .project__portfolio {
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    gap: 64px;
  }

  .project__card.featured {
    width: 1440px;
  }

  .project__card {
    width: 688px;
    border-radius: 16px;
    overflow: hidden;
  }

  .project__card.featured>a>img,
  .project__card>a>img {
    width: 100%;
    object-fit: cover;
  }

  .project__card-container {
    height: 368px;
  }

  .project__card-content h5 {
    margin-bottom: 16px;
    font-size: 48px;
    font-weight: 600;
  }
  
  .project__card-content p,
  .project__card-content a {
    font-size: 24px;
    font-weight: 500;
  }
}