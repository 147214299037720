#about-me {
  padding: 4rem 0;
  font-family: 'Mark Pro Bold', sans-serif;
  font-size: 3rem;
  color: var(--theme-secondary-light-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  opacity: 1;
  transform: translateY(-2rem);
  transition: all 0.5s ease-in 0.5s;
}

.about-me__title {
  margin-bottom: 2rem;
  font-size: 4rem;
  line-height: 5.5rem;
  letter-spacing: -0.2rem;
  text-align: center;
}

.about-me .about-me__title {
  color: var(--theme-black);
}

.about-me.dark-mode .about-me__title {
  color: var(--theme-secondary-light-white);
}

.about-me__desc {
  width: 80%;
  margin-bottom: 4rem;
  font-size: 2rem;
  text-align: center;
}

.about-me .about-me__desc {
  color: var(--theme-black);
}

.about-me.dark-mode .about-me__desc {
  color: var(--theme-secondary-light-white);
}

.about-me__deadlines {
  background: linear-gradient(-45deg,
      var(--theme-dark-red),
      var(--theme-dark-red),
      var(--theme-dark-red),
      var(--theme-secondary-dark-red),
      var(--theme-light-red),
      var(--theme-secondary-light-red),
      var(--theme-light-red),
      var(--theme-secondary-dark-red),
      var(--theme-dark-red),
      var(--theme-dark-red),
      var(--theme-dark-red));
  background-size: 2000%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 5s linear infinite;
}

.about-me__challenging {
  background: linear-gradient(-45deg,
      var(--theme-light-pink),
      var(--theme-dark-pink),
      var(--theme-light-purple),
      var(--theme-secondary-light-purple),
      var(--theme-dark-purple),
      var(--theme-secondary-dark-purple),
      var(--theme-dark-blue),
      var(--theme-secondary-dark-blue),
      var(--theme-light-blue),
      var(--theme-secondary-light-blue),
      var(--theme-light-blue),
      var(--theme-secondary-dark-blue),
      var(--theme-dark-blue),
      var(--theme-secondary-dark-purple),
      var(--theme-dark-purple),
      var(--theme-secondary-light-purple),
      var(--theme-light-purple),
      var(--theme-dark-pink),
      var(--theme-light-pink));
  background-size: 2000%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 7s linear infinite;
}

.about-me__boxes {
  margin-bottom: 4rem;
  display: flex;
  flex-flow: column;
}

.about-me .about-me__box1,
.about-me .about-me__box2,
.about-me .about-me__box3 {
  border: 2px solid var(--theme-black);
}

.about-me.dark-mode .about-me__box1,
.about-me.dark-mode .about-me__box2,
.about-me.dark-mode .about-me__box3 {
  border: 2px solid var(--theme-secondary-light-white);
}

.about-me__box1,
.about-me__box2,
.about-me__box3 {
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 1rem;
  border: 2px solid var(--theme-secondary-light-white);
  text-align: center;
}

.about-me__box1 h4 span:first-child {
  background: linear-gradient(to top, rgb(223, 4, 141) 30%, transparent 30%);
}

.about-me__box2 h4 span:first-child {
  background: linear-gradient(to top, rgb(45, 73, 216) 30%, transparent 30%);
}

.about-me__box3 h4 span:first-child {
  background: linear-gradient(to top, rgb(255, 106, 43) 30%, transparent 30%);
}

.about-me__box1 h4,
.about-me__box2 h4,
.about-me__box3 h4 {
  margin: auto;
}

.about-me__box1 h4,
.about-me__box2 h4,
.about-me__box3 h4 {
  color: var(--theme-black);
}

.about-me.dark-mode .about-me__box1 h4,
.about-me.dark-mode .about-me__box2 h4,
.about-me.dark-mode .about-me__box3 h4 {
  color: var(--theme-secondary-light-white);
}

.about-me__box1 p,
.about-me__box2 p,
.about-me__box3 p {
  margin: auto;
  margin-top: 1rem;
  font-family: 'Segoe UI', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.about-me__box1 p,
.about-me__box2 p,
.about-me__box3 p {
  color: var(--theme-black);
}

.about-me.dark-mode .about-me__box1 p,
.about-me.dark-mode .about-me__box2 p,
.about-me.dark-mode .about-me__box3 p {
  color: var(--theme-secondary-light-white);
}

/* Tablet Portrait */
@media only screen and (min-width: 768px) {
  .about-me__boxes {
    flex-flow: row;
  }

  .about-me__box1,
  .about-me__box2,
  .about-me__box3 {
    width: 16rem;
  }

  .about-me__box1 h4,
  .about-me__box2 h4,
  .about-me__box3 h4 {
    font-size: 1.8rem;
  }

  .about-me__box1 p,
  .about-me__box2 p,
  .about-me__box3 p {
    font-size: 1rem;
  }
}

/* Tablet Landscape */
@media only screen and (min-width: 1024px) {
  .about-me__box1,
  .about-me__box2,
  .about-me__box3 {
    width: 20rem;
    padding: 1.6rem;
  }

  .about-me__box1 h4,
  .about-me__box2 h4,
  .about-me__box3 h4 {
    font-size: 2.2rem;
  }

  .about-me__box1 p,
  .about-me__box2 p,
  .about-me__box3 p {
    font-size: 1.2rem;
  }
}

/* Desktop */
@media only screen and (min-width: 1440px) {
  .about-me__title {
    margin-bottom: 4rem;
    font-size: 6rem;
  }

  .about-me__desc {
    margin-bottom: 6rem;
    font-size: 2.5rem;
  }

  .about-me__box1,
  .about-me__box2,
  .about-me__box3 {
    width: 26rem;
    padding: 1.6rem;
  }

  .about-me__box1 h4,
  .about-me__box2 h4,
  .about-me__box3 h4 {
    font-size: 3rem;
  }

  .about-me__box1 p,
  .about-me__box2 p,
  .about-me__box3 p {
    font-size: 1.4rem;
  }
}
/* Max Desktop */
@media only screen and (min-width: 1920px) {
  .about-me__title {
    margin-bottom: 64px;
    font-size: 96px;
  }

  .about-me__desc {
    margin-bottom: 96px;
    font-size: 40px;
  }

  .about-me__box1,
  .about-me__box2,
  .about-me__box3 {
    width: 416px;
    padding: 25.6px;
  }

  .about-me__box1 h4,
  .about-me__box2 h4,
  .about-me__box3 h4 {
    font-size: 48px;
  }

  .about-me__box1 p,
  .about-me__box2 p,
  .about-me__box3 p {
    font-size: 22.4px;
  }
}